import { default as shape1b } from './1b.otr.pun';
import { default as shape1b2 } from './1b.pun.otr';
import { default as shape2a } from './2a.pun.hat';
import { default as shape2b } from './2b.pun.jan';
import { default as shape2c } from './2c.pun.hat';
import { default as shape3a } from './3a.otr.ban';
import { default as shape3b } from './3b.otr.ban';
import { default as shape3c } from './3c.otr.lag';
import { default as shape4a } from './4a.pun.sef';
import { default as shape4ac } from './4ac.pun.mui';
import { default as shape4b } from './4b.otr.hat';
import { default as shape4c } from './4c.otr.mui';
import { default as shape5a } from './5a.pun.mon';
import { default as shape5c } from './5c.pun.lor';
import { default as shape6a } from './6a.pun.car';
import { default as shape6b } from './6b.pun.roi';
import { default as shape7b } from './7b.pun.wet';
import { default as shape8a } from './8a.otr.cha';
import { default as shape8b } from './8b.otr.kas';
import { default as shape9a } from './9a.otr.wes';
import { default as shape10a } from './10a.otr.bon';
import { default as shape10b } from './10b.pun.bon';
import { default as shape11 } from './11.otr.ter';

let allShapes = {};
/*
allShapes.push({
  '7a':shape7a
});*/
allShapes = {...allShapes,'1b':shape1b};
allShapes = {...allShapes,'1b2':shape1b2};
allShapes = {...allShapes,'2a2b':shape2a};
allShapes = {...allShapes,'2b2c':shape2b};
allShapes = {...allShapes,'2c':shape2c};
allShapes = {...allShapes,'3a':shape3a};
allShapes = {...allShapes,'3b':shape3b};
allShapes = {...allShapes,'3c':shape3c};
allShapes = {...allShapes,'4a':shape4a};
allShapes = {...allShapes,'4a4c':shape4ac};
allShapes = {...allShapes,'4b':shape4b};
allShapes = {...allShapes,'4c':shape4c};
allShapes = {...allShapes,'5a':shape5a};
allShapes = {...allShapes,'5c':shape5c};
allShapes = {...allShapes,'6a':shape6a};
allShapes = {...allShapes,'6b':shape6b};
allShapes = {...allShapes,'7b':shape7b};
allShapes = {...allShapes,'8a':shape8a};
allShapes = {...allShapes,'8b':shape8b};
allShapes = {...allShapes,'9a':shape9a};
allShapes = {...allShapes,'10a':shape10a};
allShapes = {...allShapes,'10b':shape10b};
allShapes = {...allShapes,'11':shape11};

export default allShapes;