import React, { Component} from "react";
import decodePolyline from 'decode-google-map-polyline';

import "./Trips.style.css";

class Trips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tripItineraries: this.props.tripItineraries,
      errMessage: this.props.errMessage,
      activeItin: 0,
      activeStart: 0,
      activeEnd: 0,
      translateX: 0,
      openTrips: true,
    }
    
    //this._handleClick = this._handleClick.bind(this);
  }
  
  componentDidMount() {
      this.setState({
        tripItineraries: this.props.tripItineraries,
        activeStart: this.props.tripItineraries ? this.props.tripItineraries[this.state.activeItin].startTime : 0,
        activeEnd: this.props.tripItineraries ? this.props.tripItineraries[this.state.activeItin].endTime : 0,
        errMessage: this.props.errMessage,
      }, ()=>{this._getTripPolyline()});
  }
  
  componentDidUpdate(){
    if(this.state.tripItineraries !== this.props.tripItineraries || this.state.errMessage !== this.props.errMessage){
      this.setState({
        tripItineraries: this.props.tripItineraries,
        activeStart: this.props.tripItineraries ? this.props.tripItineraries[this.state.activeItin].startTime : 0,
        activeEnd: this.props.tripItineraries ? this.props.tripItineraries[this.state.activeItin].endTime : 0,
        errMessage: this.props.errMessage,
      }, ()=>{this._getTripPolyline()});
    }
  }
  
  _moveSlider(dir){
    const newItin = this.state.activeItin+dir;
    const sliderWidth = document.getElementById('trips__slider').offsetWidth;
    const sliderContainer = document.getElementById('trips__slider-slides');
    const activeChildHeight = sliderContainer.children[newItin].offsetHeight;
    sliderContainer.style.maxHeight = activeChildHeight + "px";
    this.setState({
      activeItin: newItin,
      translateX: this.state.translateX+(dir*(sliderWidth*-1)),
      activeStart: this.state.tripItineraries[newItin].startTime,
      activeEnd: this.state.tripItineraries[newItin].endTime,
    }, ()=>this._getTripPolyline());
  }
  
  _getTripPolyline(){
    if(this.state.tripItineraries){
      const itin = this.state.activeItin;
      const activeTrip = this.state.tripItineraries[itin];
      const polyline = [];
      if(activeTrip){
        activeTrip.legs.map((leg,index)=>{
          const latlngsObj = decodePolyline(leg.legGeometry.points);
          const latlngs = [];
          latlngsObj.map((latlngObj,i)=>{
            latlngs.push([latlngObj.lat,latlngObj.lng]);
            return null;
          });
          const color = leg.routeColor ? "#"+leg.routeColor : '#E0303B';
          const mode = leg.mode;
          const start = leg.mode==="BUS" ? {
              stopName: leg.from.name,
              departure: this._getFormattedTime(leg.from.departure)
            }
            : null;
          const finish = leg.mode==="BUS" ? {
              stopName: leg.to.name,
              arrival: this._getFormattedTime(leg.to.arrival)
            }
            : null;
          
          polyline.push({
            latlngs: latlngs,
            color: color,
            mode: mode,
            start: start,
            finish: finish
          });
          
          return null;
        });
      }
      this.props._showPolyline(polyline);
    }
  }
  
  _getFormattedTime(timestamp){
    const preDateObj = new Date(timestamp);
    // correct for timezone
    const dateObj = new Date(timestamp + preDateObj.getTimezoneOffset()*60000 - (240*60000)); 
    const hours = dateObj.getHours(); 
    const minutes = dateObj.getMinutes(); 
    const formattedTime = hours.toString().padStart(2, '0') + ':' +  minutes.toString().padStart(2, '0');
    return formattedTime;
  }
  
  render(){
    return(
      <div className={`trips__container ${!this.state.openTrips ? "-close" : ""}`}>
      {this.state.errMessage!=='' ? (
        <div className="trips__error">{this.state.errMessage}</div>
      ) : (
        this.state.tripItineraries &&
          <div className="trips__slider" id="trips__slider">
            <div className="trips__slider-header">
            {
              this.state.activeItin>0 &&
                <div className="trips__goLeft" onClick={()=>this._moveSlider(-1)}><img src="/itin-left.png" alt="Previous itinerary" /></div>
            }
            <div className="trips__time">{this._getFormattedTime(this.state.activeStart)} - {this._getFormattedTime(this.state.activeEnd)}</div>
            {
              this.state.activeItin<2 &&
                <div className="trips__goRight" onClick={()=>this._moveSlider(1)}><img src="/itin-right.png" alt="Next itinerary" /></div>
            }
            <div className="trips__openClose" onClick={()=>{this.setState({openTrips:!this.state.openTrips})}}>
              <img src="/icon-down-double.png" alt="Toggle full trips information" />
            </div>
            </div>
            <div id="trips__slider-slides" className="trips__slider-slides">
              {
                this.state.tripItineraries.map((itin,i)=>{
                  return(
                    <div className="trips__trip-container" key={i} style={{transform:'translateX('+this.state.translateX+'px)'}}>
                      {
                        itin.legs.map((leg, j) => {
                          return(
                            <div className="legRows" key={j}>
                              <div className="legRow1">
                                <img src={`/itin-${leg.mode}.png`} className="legRows__legType" alt={`Travel mode: ${leg.mode}`} />
                                {leg.mode==='WALK'
                                  ? <span className="logRows__legMode">{Math.ceil((leg.endTime-leg.startTime)/1000/60)} min - {Math.ceil(leg.distance)} m </span>
                                  : <span className="logRows__legMode">{decodeURIComponent(escape(leg.from.name).replace('%E7','c')).replace('Curacao','Curaçao')} {this._getFormattedTime(leg.from.departure)}</span>
                                }
                              </div>
                              {
                                leg.mode==='BUS' &&
                                  <div className="legRow2">
                                    <img src={`/itinIcons/itin-BUS-${leg.route}.png`} className="legRows__legType" alt={`Bus route ${leg.route}`} />
                                    <span className="logRows__legMode">{decodeURIComponent(escape(leg.to.name).replace('%E7','c')).replace('Curacao','Curaçao')} {this._getFormattedTime(leg.to.arrival)}</span>
                                  </div>
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
      )}
      </div>
    );
  }
  
}
export default Trips;