import React, { Component} from "react";

import CircularProgress from '@material-ui/core/CircularProgress';

import LeafletShape from '../LeafletShape';
import sortedRoutes from "../../assets/routeSorting.js";
import "./Schedule.style.css";

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: {},
      openRouteNr: null,
      openRoute: null,
      openStandard: [],
      openSaturdays: [],
      openSundays: [],
      showRoute: null,
      specials: []
    }
    
    this._getAllRoutes = this._getAllRoutes.bind(this);
    this._getAllTrips = this._getAllTrips.bind(this);
    this._showRoute = this._showRoute.bind(this);
    this._closeRoute = this._closeRoute.bind(this);
  }
  
  componentDidMount() {
    this._getAllRoutes();
  }
  
  componentDidUpdate(){
  }
  
  async _getAllRoutes() {
    
    const fetchAllRoutes = "https://abc.blinqwebstudio.com/otp/routes";
    fetch(fetchAllRoutes)
    .then(response => response.text())
    .then((response) => {
      let responseObj = JSON.parse(response);
      //console.log('allRoutes',responseObj);
      const sorted = responseObj.sort((a, b) => sortedRoutes[a.id] -sortedRoutes[b.id]);
      //console.log('sorted',sorted);
      this.setState({routes:sorted});
    }).catch((err) => {
        console.log('fetch', err)
    })
    
  }
  
  _getTimeFromId(allTrips){
    const longVal = allTrips['id'].split(':')[1];
    const justNum = longVal.split('.')[(longVal.split('.').length-1)].match(/\d/g);
    let time = justNum.join("");
    time = time.slice((time.lenght*-1),(time.length-2)) + ':' + time.slice(-2,time.length);
    return time;
  }
  
  async _getAllTrips(routeID){
    
    if(routeID===this.state.openRouteNr) {
      this.setState({
        openRouteNr: null,
        openRoute: null,
        openStandard: [],
        openSaturdays: [],
        openSundays: [],
        specials: []
      });
    }
    else {
      this.setState({
        openRouteNr: routeID,
        openRoute: null,
        openStandard: [],
        openSaturdays: [],
        openSundays: [],
        specials: []
      });
      const fetchAllTrips = "https://abc.blinqwebstudio.com/otp/routetrips?routeID="+routeID;
      fetch(fetchAllTrips)
      .then(response => response.text())
      .then((response) => {
        let responseObj = JSON.parse(response);
        //console.log('alltrips',responseObj);
        let standard = [];
        let saturdays = [];
        let sundays = [];
        const specials = [];
        let special = false;
        let theTime = '';
        let asterisk = '';
        let i = 0;
        Object.keys(responseObj).map((key,index)=> {
          const thisTrip = responseObj[key];
          special = false;
          if(Object.keys(thisTrip).includes('tripHeadsign')) {
            if(!specials.includes(thisTrip['tripHeadsign'])){
              specials.push(thisTrip['tripHeadsign']);
            }
            special = true;
          }
          if(special){
            asterisk = '';
            for(i=0;i<specials.length;i++){
              asterisk += '*';
              if(specials[i]===thisTrip['tripHeadsign']) break;
            }
          }
          theTime = special ? this._getTimeFromId(thisTrip) + asterisk : this._getTimeFromId(thisTrip);
          switch(thisTrip['serviceId'].split(':')[1]){
            case 'standard':
              standard.push(theTime);
              break;
            case 'saturdays':
              saturdays.push(theTime);
              break;
            case 'sundays':
              sundays.push(theTime);
              break;
            default:
              standard.push(theTime);
              break;
          }
        });
        //console.log('standard',standard.sort(function(a, b){return parseInt(a.match(/\d/g).join(''))-parseInt(b.match(/\d/g).join(''))}));
        standard = standard.sort(function(a, b){return parseInt(a.match(/\d/g).join(''))-parseInt(b.match(/\d/g).join(''))});
        saturdays = saturdays.sort(function(a, b){return parseInt(a.match(/\d/g).join(''))-parseInt(b.match(/\d/g).join(''))});
        sundays = sundays.sort(function(a, b){return parseInt(a.match(/\d/g).join(''))-parseInt(b.match(/\d/g).join(''))});
        
        this.setState({
          openRouteNr: routeID,
          openRoute: responseObj,
          openStandard: standard,
          openSaturdays: saturdays,
          openSundays: sundays,
          specials: specials
        });
      }).catch((err) => {
          console.log('fetch', err)
      })
    }
  }
  
  _showRoute(routeID,override=false) {
    this.setState({
      showRoute: override!==false ? override.toLowerCase() : routeID.toLowerCase()
    });
  }
  
  _closeRoute() {
    this.setState({
      showRoute: null
    });
  }
  
  render(){
    return(
      <>
        {this.state.showRoute!==null 
          ? (
            <div className="shape__wrapper">
              <LeafletShape routeID={this.state.showRoute} doClose={this._closeRoute} />
            </div>
          )
          : (
            <div className="schedule__wrapper">
              <div className="schedule">
                {Object.keys(this.state.routes).length>0
                  ? (
                      Object.keys(this.state.routes).map((key,index)=> {
                        const thisRoute = this.state.routes[key];
                        const thisColor = '#'+thisRoute['color'];
                        // ignore these 2 because they're fragments
                        if(thisRoute['shortName']!=='2AB' && thisRoute['shortName']!=='2BC') {
                          return (
                            <div className={`schedule__route-row ${this.state.openRouteNr===thisRoute['id'] ? '-open' : '-closed'}`} style={{borderColor: thisColor}} key={index}>
                              <div className="schedule__route-name" style={{borderColor: thisColor}} onClick={() => this._getAllTrips(thisRoute['id'])}>
                                <div className="route-row-img">
                                  <img src="/schedule-bus.png" alt="Schedule of route" />
                                </div>
                                <div className="route-row-txt">
                                  {thisRoute.shortName} {thisRoute.longName}
                                </div>
                              </div>
                              <div className="schedule__route-map" style={{borderColor: thisColor}} onClick={() => this._showRoute(thisRoute['shortName'],thisRoute['id']==='1:10' ? '1B2' : false )}>
                                <img src="/schedule-map.png" alt="Map of route" />
                              </div>
                              {this.state.openRouteNr===thisRoute['id']
                                ? this.state.openStandard.length>0 || this.state.openSaturdays.length>0 || this.state.openSundays.length>0
                                  ? (
                                    <div className="schedule__route-oneroute">
                                      { this.state.openStandard.length>0 &&
                                        <div>
                                          <h3>Weekdays</h3>
                                          {this.state.openStandard.map((time,i)=> {
                                            return(
                                              <span key={i}>
                                                {time}
                                              </span>
                                            )
                                          })}
                                        </div>
                                      }
                                      { this.state.openSaturdays.length>0 &&
                                        <div>
                                          <h3>Saturdays</h3>
                                          {this.state.openSaturdays.map((time,i)=> {
                                            return(
                                              <span key={i}>
                                                {time}
                                              </span>
                                            )
                                          })}
                                        </div>
                                      }
                                      { this.state.openSundays.length>0 &&
                                        <div>
                                          <h3>Sundays</h3>
                                          {this.state.openSundays.map((time,i)=> {
                                            return(
                                              <span key={i}>
                                                {time}
                                              </span>
                                            )
                                          })}
                                        </div>
                                      }
                                      { this.state.specials.length>0 &&
                                        <div className="specials">
                                          {this.state.specials.map((special,i)=> {
                                            let asterisks = '';
                                            for(let j=0;j<=i;j++){
                                              asterisks += "*";
                                            }
                                            return(
                                              <div key={i}>
                                                {asterisks} {special}
                                              </div>
                                            )
                                          })}
                                          
                                        </div>
                                      }
                                    </div>
                                  )
                                  : (
                                    <div className="schedule__loading">
                                      <CircularProgress color="inherit" />
                                    </div>
                                  )
                                : (null)
                              }
                            </div>
                          )
                        }
                      })
                  )
                  : (
                    <div className="schedule__loading">
                      <CircularProgress color="inherit" />
                    </div>
                  )
                }
              </div>
              <div className="clr"></div>
            </div>
          )
        }
      </>
    )
  }
  
}
export default Schedule;