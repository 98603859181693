import React, { Component} from "react";
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMapEvent } from 'react-leaflet'

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';

import "./LeafletStops.style.css";

 
function ZoomComponent(p) {
  const map = useMapEvent('zoom', (e) => {
    //p.clickIt([e.latlng.lat.toFixed(6),e.latlng.lng.toFixed(6)]);
    p.zoomIt(e.target._zoom);
  });
  
  return false;
}
const markerStop = L.icon({
  iconUrl: 'icon-stop.png',
  iconSize: [18, 18],
  iconAnchor: [9, 9]
});
const markerCircle = L.icon({
  iconUrl: 'icon-circle.png',
  iconSize: [10, 10],
  iconAnchor: [5, 5]
});

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#E0303B',
    }
  },
});

class LeafletStops extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      stops: [],
      zoomLevel: 13,
      stopLines: '',
      nextBuses: '',
      loadingLines: false,
      loadingBuses: false,
    }
    
    //this._handleClick = this._handleClick.bind(this);
  }
  
  componentDidMount() {
    this._getAllStops();
  }
  
  async _getAllStops(){
    
    const fetchAllStops = "https://abc.blinqwebstudio.com/otp/stops";
    await fetch(fetchAllStops)
    .then(response => response.text())
    .then((response) => {
      let responseObj = JSON.parse(response);  
      //console.log(responseObj);
      this.setState({
        stops: responseObj,
        loading: false
      });
    }).catch((err) => {
        //console.log('fetch', err)
        this.setState({ loading: false });
    })
  }
  
  async _popupOpen(thisCode){
    this.setState({
      loadingLines: true,
      loadingBuses: true,
    });
    //console.log('open',thisCode);
    
    // get the bus lines
    const annRoutes = [];
    const fetchStopRoutes = "https://abc.blinqwebstudio.com/otp/stoproutes?stopcode="+thisCode;
    fetch(fetchStopRoutes)
    .then(response => response.text())
    .then(async (response) => {
      let responseObj = JSON.parse(response);
      //console.log(responseObj);
      if(responseObj.length>0){
        const stopLines0 = responseObj.reduce((result,line)=>{
          if(line.shortName!=="2AB" && line.shortName!=="2BC"){
            result.push(line);
          }
          return result;
        },[]);
        const stopLines = stopLines0.map((line,index)=>{
          annRoutes.push(line);
          return (
            <img src={`/itinIcons/itin-BUS-${line.shortName}.png`} alt={`Bus route ${line.shortName}`} className="stopline__img" key={index} />
          );
        });
        this.setState({
          loadingLines: false,
          stopLines: stopLines,
        });
      }
      else {
        this.setState({
          loadingLines: false,
          stopLines: 'This stop doesn\'t have service right now.',
        });
      }
    }).catch(async (err) => {
        this.setState({
          loadingLines: false,
        });
    })
    
    
    // get the next arriving buses
    //const fetchNextBuses = "http://otpabc.blinqwebstudio.com:8080/otp/routers/default/index/stops/1:"+thisCode+"/stoptimes?numberOfDepartures=3";
    const fetchNextBuses = "https://abc.blinqwebstudio.com/otp/stoptimes?stopcode="+thisCode+"&numberOfDepartures=3";
    await fetch(fetchNextBuses)
    .then(responseNext => responseNext.text())
    .then(async (responseNext) => {
      const nextBuses = JSON.parse(responseNext);
      //console.log(nextBuses);
      
        // create array without duplicates
        // strange key to prevent values being overwritten
        let routesAr = [];
        let routeIdDesc = [];
        annRoutes.map((route, i) => {
          routesAr.push(route.shortName);
          routeIdDesc[route.id] = route.longName;
        });
        let unique = [...new Set(routesAr)]; 
        
        let nextBusTimes = {};
        nextBuses.map((busLine, i) => {
          busLine.times.map((time, j) => {
            //console.log(busLine);
            if(time.stopIndex!==time.stopCount-1){
              if(!(busLine.pattern.desc+'_'+time.realtimeDeparture in nextBusTimes)){
                //console.log('busLine',busLine);
                let routeIdAr = busLine.pattern.id.split('::');
                let routeDesc = routeIdDesc[routeIdAr[0]] ? routeIdDesc[routeIdAr[0]] : '';
                //nextBusTimes[busLine.pattern.desc+'_'+time.realtimeDeparture] = [busLine.pattern.desc, time.realtimeDeparture, routeDesc];
                nextBusTimes[busLine.pattern.desc+'_'+time.realtimeDeparture] = [busLine.pattern.desc, time.realtimeDeparture, routeDesc];
                //console.log('nextBusTimes',nextBusTimes);
              }
            }
          });
        });
        //console.log('nextBusTimes',nextBusTimes);
        // turn the resulting array into one we can sort while still maintaining busline-time connection
        let nextBusTimes2 = [];
        const now = new Date();
        const nowTimestamp = now.getTime();
        const offsetHours = (240 - now.getTimezoneOffset())/60; // col = -1
        const useDate = new Date(nowTimestamp - (offsetHours*60*60*1000));
        const then = new Date( useDate.getFullYear(), useDate.getMonth(), useDate.getDate(), offsetHours,0,0);
        //console.log(now.toString(), then.toString(), online, offsetHours);
        //console.log(this.state.annNextBuses);
        const nowMilliSeconds = now.getTime() - then.getTime();
        Object.keys(nextBusTimes).forEach(function(key, index) {
          let minFromNow = (nextBusTimes[key][1] - (nowMilliSeconds/1000)) / 60;
          //console.log('nextBusTimes',nextBusTimes);
          if(minFromNow>0 && minFromNow<100) // to remove next-day departures and departures too far in the future
            nextBusTimes2.push([nextBusTimes[key][0], Math.floor(minFromNow), nextBusTimes[key][2]]);
        });
        nextBusTimes2.sort(function(a,b) {
            return a[1]-b[1];
        })
        //console.log(nextBusTimes2);
        
        let nextBusesShow = 'There are no scheduled buses here between now and 100 minutes.';
        if(nextBusTimes2.length > 0) {
          nextBusesShow = nextBusTimes2.map((info,i)=>{
            if(i>2) return null;
            return(
              <div className="stops__popup-nextbus" key={i}>
                <div className="stops__popup-nextbus-imgtime">
                  <img src={`/itinIcons/itin-BUS-${info[0].substr(0,3).trim()}.png`} alt={`BUS ${info[0].substr(0,3).trim()}`} /> 
                  <div className="stops__popup-nextbus-time">{info[1]} min</div>
                </div>
                <div className="stops__popup-nextbus-desc">{info[2]}</div>
              </div>
            );
          });
        }
        
        this.setState({
          loadingBuses: false,
          nextBuses: nextBusesShow,
        });
        
      
    }).catch((err) => {
        console.log('fetchNextBuses', err)
        this.setState({
          loadingBuses: false,
        });
    })
    
  }
  
  _popupClose(e){
    //console.log('close',e);
    this.setState({
      stopLines: '',
      nextBuses: '',
    });
  }
  
  _handleZoom(p){
    //console.log('zoom handled',p);
    this.setState({
      zoomLevel: p
    });
  }
  
  _getFormattedDateTime(timestamp,which){
    const dateObj = new Date(timestamp); 
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth()+1;
    const day = dateObj.getDate();
    const hours = dateObj.getHours(); 
    const hoursUS = hours > 12 ? hours-12 : hours;
    const ampm = hours > 11 ? 'pm' : 'am';
    const minutes = dateObj.getMinutes(); 
    const formattedDate = month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0') + '-' + year.toString();
    const formattedTime = hoursUS.toString() + ':' +  minutes.toString().padStart(2, '0') + ampm;
    return which==='date' ? formattedDate : formattedTime;
  }
  
  
  render(){
    return(
      <ThemeProvider theme={theme}>
        <div id="wrapper">
          <MapContainer center={[12.131712, -68.934012]} zoom={13} zoomControl={false}>
            <ZoomControl position='bottomleft' />
            <ZoomComponent zoomIt={(pass)=>this._handleZoom(pass)} />
            
            {this.state.stops.length>0 &&
              this.state.stops.map((stop,index)=>{
                return(
                  <Marker
                    position={[stop.lat,stop.lon]}
                    icon={this.state.zoomLevel < 15 ? markerCircle : markerStop}
                    key={index}
                  >
                    <Popup
                      onOpen={()=>this._popupOpen(stop.code)}
                      onClose={()=>this._popupClose(stop.code)}
                      className="stops__popup"
                    >
                      <h3>{stop.name} </h3>
                      <div className="stops__popup-buslines">
                        <strong>Buslines</strong><br />
                        {this.state.stopLines}
                        {this.state.loadingLines &&
                          <CircularProgress color="inherit" />
                        }
                      </div>
                      <div className="clr"></div>
                      
                      <div className="stops__popup-nextbuses">
                        <strong>Next Bus</strong><br />
                        {this.state.nextBuses}
                        {this.state.loadingBuses &&
                          <CircularProgress color="inherit" />
                        }
                      </div>
                      <div className="clr"></div>
                    </Popup>
                  </Marker>
                )
              })
            }
            
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </MapContainer>
          {this.state.loading &&
            <LinearProgress  color="secondary" className="mainload"/>
          }
        </div>
      </ThemeProvider>
    );
  }
  
}
export default LeafletStops;