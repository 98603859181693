// route ID : number for sorting
let sortedRoutes={
  '1:10' : 10, //1b
  '1:11' : 20, //1b Otrobanda
  '1:30' : 30, //2a
  '1:70' : 40, //2b
  '1:50' : 50, //2c
  '1:100' : 60, //3a
  '1:120' : 70, //3b 
  '1:140' : 80, //3c 
  '1:90' : 90, //4a
  '1:91' : 100, //4a4c
  '1:170' : 110, //4b
  '1:240' : 120, //4c
  '1:110' : 130, //5a
  '1:130' : 140, //5c
  '1:150' : 150, //6a
  '1:20' : 160, //6b
  '1:65' : 170, //7b
  '1:190' : 180, //8a
  '1:210' : 190, //8b
  '1:220' : 200, //9a
  '1:300' : 210, //10a
  '1:80' : 220, //10b
  '1:232' : 230, //11
};


export default sortedRoutes;




