import React, { Component} from "react";
import logo from './logo.svg';
import './App.css';

import LeafletMap from "./components/LeafletMap";
import LeafletStops from "./components/LeafletStops";
import Schedule from "./components/Schedule";

class App extends Component{
  constructor(props) {
    super(props);
    this.state = {
    };
    //this.modalAbout = this.modalAbout.bind(this);
  }  
  
  render() {
    return ( window.location.search==='?stops' ?  <LeafletStops /> : (window.location.search==='?schedule' ? <Schedule /> : <LeafletMap />) )
      
  }
}

export default App;
