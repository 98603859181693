import React, { Component} from "react";
import L from 'leaflet';
import { parseString } from 'xml2js';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMap, useMapEvent, Polyline } from 'react-leaflet'

import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Trips from '../Trips';
import "./LeafletMap.style.css";

  
function ClickComponent(p) {
  const map = useMapEvent('click', (e) => {
    //console.log('click on map',e);
    p.clickIt([e.latlng.lat.toFixed(6),e.latlng.lng.toFixed(6)]);
  });
  
  return false;
}
let curBounds = null;
function BoundsControl(b){
  const map = useMap();
  const myBounds = b.bounds;
  //console.log(b,curBounds);
  if(b.bounds.length>0 && (curBounds===null || (myBounds[0][0]!==curBounds[0][0] && myBounds[1][1]!==curBounds[1][1]))) {
    const newBounds = [
      [myBounds[0][0]-0.05,myBounds[0][1]],
      [myBounds[1][0],myBounds[1][1]+0.05]
    ];
    map.fitBounds(newBounds);
    curBounds = myBounds;
  }
  return false;
}

const markerStart = L.icon({
  iconUrl: 'marker-start.png',
  iconSize: [44, 44],
  iconAnchor: [22, 44]
});
const markerFinish = L.icon({
  iconUrl: 'marker-finish.png',
  iconSize: [44, 44],
  iconAnchor: [22, 44]
});
const markerStop = L.icon({
  iconUrl: 'icon-stop.png',
  iconSize: [18, 18],
  iconAnchor: [9, 9]
});

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#E0303B',
    }
  },
});
  
  
class LeafletMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
      from: '',
      to: '',
      whenDate: '',
      whenTime: '',
      fromtoSelected: 'fromto_from',
      loadingTrip: null,
      polylines: '',
      bounds: [],
      openFromTo: true,
    }
    
    this._handleClick = this._handleClick.bind(this);
    
    this.mapRef = React.createRef();
  }
  
  componentDidMount() {
  }
  
  _resetTrip(){
    this.setState({
      markers: [],
      from: '',
      to: '',
      whenDate: '',
      whenTime: '',
      fromtoSelected: 'fromto_from',
      loadingTrip: null,
      polylines: '',
      bounds: [],
      openFromTo: true,
      openDatetime: false,
    });
  }
  
  _getTrips(){
    //const date = new Date(2020,11,25,15,0,0);
    const dateD = this.state.whenDate!=='' ? new Date(this.state.whenDate) : new Date();
    const dateT = this.state.whenTime!=='' ? new Date(this.state.whenTime) : new Date();
    //const date = new Date(2020,11,25,15,0,0);
    //const dateNow = date.getTime();
    const fetchRoute = "https://abc.blinqwebstudio.com/otp/plan?fromPlace="+this.state.from+"&toPlace="+this.state.to+"&time="+this._getFormattedDateTime(dateT,'time')+"&date="+this._getFormattedDateTime(dateD,'date')+"&mode=TRANSIT,WALK&maxWalkDistance=900&useTransfersTxt=true&arriveBy=false";
    // console.log(fetchRoute);
    
    this.setState({
      tripItineraries: null,
      loadingTrip: true,
    },
      () => fetch(fetchRoute)
      .then(response => response.text())
      .then((response) => {
        parseString(response, function (err, result) {
          const responseObj = JSON.parse(response);
          if (responseObj.error) {
            this.setState({
              errMessage: responseObj.error.msg,
              tripItineraries: null,
              loadingTrip: false,
            });
          }
          else {
            // console.log(responseObj);
            this.setState({
              loadingTrip: false,
              tripItineraries: responseObj.plan.itineraries,
              errMessage: '',
              openFromTo: false,
            });
          }
        }.bind(this));
      }).catch((err) => {
        console.log('fetch', err);
        this.setState({
          errMessage: 'We couldn\' connect to the trip planner service. Please try again later.'
        });
      })
    );
  }
  
  _handleClick(latlng) {
    //console.log(latlng,this.state.from,this.state.to);
    if(latlng[0]!==this.state.to[0] && latlng[1]!==this.state.to[1] && this.state.fromtoSelected==='fromto_from' && this.state.openFromTo){
      this.setState({
        from: latlng,
        fromtoSelected: 'fromto_to'
      });
    }
    else if(latlng[0]!==this.state.from[0] && latlng[1]!==this.state.from[1] && this.state.openFromTo){
      this.setState({
        to: latlng,
        fromtoSelected: 'fromto_from'
      });
    }
  }
  
  _makeLineActive(which){
    this.setState({
      fromtoSelected: which
    });
  }
  
  _showPolyline(legs){
    // console.log('yoohoo',legs);
    const legBounds = [];
    const polylines = legs.map((leg,i)=>{
      legBounds.push(leg.latlngs);
      return (
        <React.Fragment key={i}>
          <Polyline
            pathOptions={{color: leg.color}}
            positions={leg.latlngs}
            key={i}
          />
          {
            leg.mode==='BUS' ? (
              <React.Fragment>
                <Marker 
                  position={leg.latlngs[0]}
                  icon={markerStop}
                >
                  <Popup className="popupABC">
                    Stop: {leg.start.stopName}<br />
                    Departure: {leg.start.departure}
                  </Popup>
                </Marker>
                <Marker 
                  position={leg.latlngs[leg.latlngs.length-1]}
                  icon={markerStop}
                >
                  <Popup className="popupABC">
                    Stop: {leg.finish.stopName}<br />
                    Arrival: {leg.finish.arrival}
                  </Popup>
                </Marker>
              </React.Fragment>
            ) : (null)
          }
        </React.Fragment>  
      )
    });
    this.setState({
      polylines: polylines,
    });
    
    // set bounds
    // console.log(legBounds,'legBounds');
    let bounds = null;
    if(legBounds.length>0){
      // find the bounding box
      const lats = [];
      const lons = [];
      legBounds.map((latlngs,i)=> {
        latlngs.map((latlng,j)=>{
          lats.push(latlng[0]);
          lons.push(latlng[1]);
        });
      });
      const minLat = Math.min.apply(null, lats);
      const maxLat = Math.max.apply(null, lats);
      const minLon = Math.min.apply(null, lons);
      const maxLon = Math.max.apply(null, lons);
      // NorthEast, SouthWest
      bounds = [[minLat,minLon],[maxLat,maxLon]];
      // console.log(bounds,'bounds');
      this.setState({
        bounds: bounds,
      });
      
    }
  }
  
  _handleDateChange(date,which){
    const d = new Date(date);
    if(which==='date'){
      this.setState({
        whenDate: d,
        whenTime: this.state.whenTime!=='' ? this.state.whenTime : d,
      });
    }
    else {
      this.setState({
        whenDate: this.state.whenDate!=='' ? this.state.whenDate : d,
        whenTime: d,
      });
    }
  }
  
  _getFormattedDateTime(timestamp,which){
    const dateObj = new Date(timestamp); 
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth()+1;
    const day = dateObj.getDate();
    const hours = dateObj.getHours(); 
    const hoursUS = hours > 12 ? hours-12 : hours;
    const ampm = hours > 11 ? 'pm' : 'am';
    const minutes = dateObj.getMinutes(); 
    const formattedDate = month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0') + '-' + year.toString();
    const formattedTime = hoursUS.toString() + ':' +  minutes.toString().padStart(2, '0') + ampm;
    return which==='date' ? formattedDate : formattedTime;
  }
  
  
  render(){
    let placeholderFrom = this.state.fromtoSelected==='fromto_from' && this.state.from==='' ? 'Tap the map to select' : (this.state.from==='' ? 'Not selected' : this.state.from[0]+","+this.state.from[1]);
    let placeholderTo = this.state.fromtoSelected==='fromto_to' && this.state.to==='' ? 'Tap the map to select' : (this.state.to==='' ? 'Not selected' : this.state.to[0]+","+this.state.to[1]);
    
    return(
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div id="wrapper">
            <div id="fromto__wrapper" className={!this.state.openFromTo ? "-close" : ""}>
              <div id="fromto">
                <div className="fromto__close" onClick={()=>{this.setState({openFromTo:!this.state.openFromTo})}}>
                  Close &#10006;
                </div>
                <div className="fromto__open" onClick={()=>{this._resetTrip()}}>
                  Reset &#8635;
                </div>
                <h1>Where would you like to go?</h1>
                <form className="fromto__form" noValidate autoComplete="off">
                  <div className={`fromto__locline ${(this.state.fromtoSelected==='fromto_from' ? 'active' : '')}`} onClick={()=>this._makeLineActive('fromto_from')}>
                    <div className="fromto__locleft">From:</div> 
                    <TextField placeholder={placeholderFrom} id="fromto_from" className="fromto__field" color="secondary" disabled />
                  </div>
                  <div className={`fromto__locline ${(this.state.fromtoSelected==='fromto_to' ? 'active' : '')}`} onClick={()=>this._makeLineActive('fromto_to')}>
                    <div className="fromto__locleft">To:</div> 
                    <TextField placeholder={placeholderTo} id="fromto_to" className="fromto__field" color="secondary" disabled />
                  </div>
                  <div className={`fromto__locline ${this.state.openDatetime ? '' : '-close'}`}>
                    <div className="fromto__locleft">When:</div> 
                    <DatePicker value={this.state.whenDate!=='' ? this.state.whenDate : new Date()} onChange={(e)=>this._handleDateChange(e,'date')} className="fromto__field-small" />
                    <TimePicker value={this.state.whenTime!=='' ? this.state.whenTime : new Date()} onChange={(e)=>this._handleDateChange(e,'time')} className="fromto__field-small" />
                  </div>
                  <div className="fromto__plan">
                    <div className={`fromto__plan-openclose ${this.state.openDatetime ? '' : '-close'}`} onClick={()=>{this.setState({openDatetime:!this.state.openDatetime})}}>
                      <img src="/icon-up-double.png" alt="Toggle date/time selection" />
                    </div>
                    <Button 
                      size="small" 
                      disabled={this.state.from==='' || this.state.to==='' ? true : false}
                      color={this.state.from==='' || this.state.to==='' ? 'default' : 'secondary'}
                      variant={this.state.from==='' || this.state.to==='' ? 'text' : 'contained'}
                      onClick={()=>this._getTrips()}
                    >
                      Plan your trip
                    </Button>
                  </div>
                  <div className="clr"></div>
                </form>
              </div>
            </div>
            
            {this.state.loadingTrip!==null &&
              <div id="trips__wrapper">
              {this.state.loadingTrip ? (
                  <LinearProgress  color="secondary" />
                ) : (
                  <Trips 
                    tripItineraries={this.state.tripItineraries} 
                    errMessage={this.state.errMessage}
                    _showPolyline={(s)=>this._showPolyline(s)}
                  />
                )
              }
              </div>
            }
            
            <MapContainer center={[12.131712, -68.934012]} bounds={this.state.bounds} zoom={13} zoomControl={false}>
              <ClickComponent clickIt={(pass)=>this._handleClick(pass)} />
              <BoundsControl bounds={this.state.bounds} />
              <ZoomControl position='bottomleft' />
              
              {this.state.from!=='' &&
                <Marker 
                  position={this.state.from}
                  icon={markerStart}
                >
                </Marker>
              }
              
              {this.state.to!=='' &&
                <Marker 
                  position={this.state.to}
                  icon={markerFinish}
                >
                </Marker>
              }
              
              {this.state.polylines!=='' &&
                this.state.polylines
              }
              
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </MapContainer>
          
          </div>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }
  
}
export default LeafletMap;