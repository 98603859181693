import React, { Component} from "react";
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl, useMap, useMapEvent, Polyline } from 'react-leaflet';

import allShapes from '../../assets/shapes/routeShapes';

import "./LeafletShape.style.css";

let curBounds = null;
function BoundsControl(b){
  const map = useMap();
  const myBounds = b.bounds;
  //console.log(b,curBounds);
  if(b.bounds.length>0 && (curBounds===null || (myBounds[0][0]!==curBounds[0][0] && myBounds[1][1]!==curBounds[1][1]))) {
    const newBounds = [
      [myBounds[0][0],myBounds[0][1]],
      [myBounds[1][0],myBounds[1][1]]
    ];
    map.fitBounds(newBounds);
    curBounds = myBounds;
  }
  return false;
}

class LeafletShape extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapLoaded: false,
      lineShape: null,
      latitude: null,
      longitude: null,
      shapeArrays: this.props.routeID && allShapes[this.props.routeID]!==undefined ? allShapes[this.props.routeID] : [], //allShapes['7a'], // this one just for initial testing, needs to come from allShapes and props
      bounds: [],
      polyline: null,
      doClose: this.props.doClose
    }
    
    this._getTheLine = this._getTheLine.bind(this);
  }
  
  componentDidMount() {
    this._getTheLine();
  }
  
  componentDidUpdate(){
    
  }
  
  _getTheLine(){
    if(this.state.shapeArrays.length>0){
      // set up the line
      const latlngs = this.state.shapeArrays.map((item,i)=>{
        return( [item[1],item[0]] );
      });
      const polyline =  <Polyline
          pathOptions={{color: '#E0303B'}}
          positions={latlngs}
        />
      
      // find the bounding box
      const lats = [];
      const lons = [];
      this.state.shapeArrays.map((lonLat,i)=> {
        lats.push(lonLat[1]);
        lons.push(lonLat[0]);
      });
      const minLat = Math.min.apply(null, lats);
      const maxLat = Math.max.apply(null, lats);
      const minLon = Math.min.apply(null, lons);
      const maxLon = Math.max.apply(null, lons);
      // NorthEast, SouthWest
      const bounds = [[minLat,minLon],[maxLat,maxLon]];
      // console.log(bounds,'bounds');
      this.setState({
        bounds: bounds,
        polyline: polyline
      });
    }
  }
  
  render(){
    return(
          <div id="wrapper">
            <div className="leafletShape__close" onClick={()=>this.props.doClose()}>
              Close &#10005;
            </div>
            <MapContainer center={[12.131712, -68.934012]} bounds={this.state.bounds} zoom={13} zoomControl={false}>
              <BoundsControl bounds={this.state.bounds} />
              <ZoomControl position='bottomleft' />
              
              {this.state.polyline!==null &&
                this.state.polyline
              }
              
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </MapContainer>
          </div>
    
    
    )
  }
}
export default LeafletShape;
  